<template>
  <section class="section section-hero">
    <div class="section-hero-bg">
      <section-title
        icon="icon-about"
        h2="Cikkek, írások, elemzések"
        h2small="Blogunk olyan témákkal foglalkozik, amelyekkel napi szinten találkozunk, dolgozunk."
        createdate=""
      />
    </div>
    <div class="section section-hero-body">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-3 col-blog col-blog-design">
            <div class="d-grid col-lg-8 m-auto">
              <h3>// Design</h3>
              <router-link
                @click="hidden = !hidden"
                class="btn btn-rainbow pt-4 pb-4"
                to="#filozofiank"
              >
                <span>Bővebben</span>
              </router-link>
            </div>
          </div>
          <div class="col-12 col-lg-3 col-blog col-blog-frontend-development">
            <div class="d-grid col-lg-8 m-auto">
              <h3>// Frontend</h3>
              <router-link
                @click="hidden = !hidden"
                class="btn btn-rainbow pt-4 pb-4"
                to="#filozofiank"
              >
                <span>Bővebben</span>
              </router-link>
            </div>
          </div>
          <div class="col-12 col-lg-3 col-blog col-blog-sitebuild">
            <div class="d-grid col-lg-8 m-auto">
              <h3>// Sitebuild</h3>
              <router-link
                @click="hidden = !hidden"
                class="btn btn-rainbow pt-4 pb-4"
                to="#filozofiank"
              >
                <span>Bővebben</span>
              </router-link>
            </div>
          </div>
          <div class="col-12 col-lg-3 col-blog col-blog-e-commerce">
            <div class="d-grid col-lg-8 m-auto">
              <h3>// E-commerce</h3>
              <router-link
                @click="hidden = !hidden"
                class="btn btn-rainbow pt-4 pb-4"
                to="#filozofiank"
              >
                <span>Bővebben</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section-title
    icon="icon-blog"
    h2="Blog"
    h2small="Hírek, érdekességek, elemzések"
  />

  <section class="section section-blog">
    <div class="container">
      <div class="row">
        <SectionBlogCards
          size="2"
          badge="new"
          url="vue-js-3-projekt-struktura-alapok"
          img="img-placeholder-636.png"
          imgalt="Vue.js 3 projekt struktúra alapok"
          h3="Vue.js 3 projekt struktúra alapok"
          lead="In this lesson, we'll learn how to install the latest version of the Vue
                CLI (called next) that allows us to use Vue 3. We will also take a look
                through the project structure we get when…"
          createdate="2020.01.01."
          tag1="tanfolyam"
          tag1url=""
          tag2="frontend"
          tag2url="szolgaltatasaink/frontend"
          tag3="Vue.js 3"
          tag3url="szolgaltatasaink/frontend/vuejs"
        />

        <SectionBlogCards
          size="2"
          badge="none"
          url="vue-js-3-projekt-struktura-alapok"
          img="img-placeholder-636.png"
          imgalt="Tesztmaki"
          h3="Tesztmakih3"
          lead="Tesztmakilead"
          createdate="2020.11.11."
          tag1="tanfolyam"
          tag1url=""
          tag2="frontend"
          tag2url=""
          tag3="Vue.js 3"
          tag3url=""
        />
      </div>
    </div>

    <div class="container">
      <div class="row equal-cols">
        <SectionBlogCards
          size="3"
          badge="none"
          url="vue-js-3-projekt-struktura-alapok"
          img="img-placeholder-416.png"
          imgalt="Tesztmaki"
          h3="Tesztmakih3"
          lead="Tesztmakilead"
          createdate="2020.11.11."
          tag1="tanfolyam"
          tag1url=""
          tag2="frontend"
          tag2url=""
          tag3="Vue.js 3"
          tag3url=""
        />

        <SectionBlogCards
          size="3"
          badge="none"
          url="vue-js-3-projekt-struktura-alapok"
          img="img-placeholder-416.png"
          imgalt="Tesztmaki"
          h3="Tesztmakih3"
          lead="Tesztmakilead"
          createdate="2020.11.11."
          tag1="tanfolyam"
          tag1url=""
          tag2="frontend"
          tag2url=""
          tag3="Vue.js 3"
          tag3url=""
        />

        <SectionBlogCards
          size="3"
          badge="none"
          url="vue-js-3-projekt-struktura-alapok"
          img="img-placeholder-416.png"
          imgalt="Tesztmaki"
          h3="Tesztmakih3"
          lead="Tesztmakilead"
          createdate="2020.11.11."
          tag1="tanfolyam"
          tag1url=""
          tag2="frontend"
          tag2url=""
          tag3="Vue.js 3"
          tag3url=""
        />
      </div>
    </div>
  </section>
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";
import SectionBlogCards from "@/components/SectionBlogCards.vue";

export default {
  components: {
    SectionTitle,
    SectionBlogCards,
  },
  data() {
    return {};
  },
};
</script>
