<template>
  <div v-if="size == 2" class="col-12 col-lg-6">
    <div class="card">
      <div class="card-img">
        <img
          class="card-img-top"
          :src="require('@/assets/img/blog/' + img)"
          :alt="imgalt"
        />
        <img
          v-if="badge == 'none'"
          class="card-badge"
          src="@/assets/img/all/badge-none.svg"
          alt=""
        />
        <img
          v-if="badge == 'new'"
          class="card-badge"
          src="@/assets/img/all/badge-new.svg"
          alt="Újdonság"
        />
        <img
          v-if="badge == 'fresh'"
          class="card-badge"
          src="@/assets/img/all/badge-fresh.svg"
          alt="Friss"
        />
        <img
          v-if="badge == 'premium'"
          class="card-badge"
          src="@/assets/img/all/badge-premium.svg"
          alt="Prémium"
        />
      </div>
      <div class="card-header">
        <div class="row">
          <div class="col-12 col-lg-3">
            <span class="list-date" v-html="createdate"></span>
          </div>
          <div class="col-12 col-lg-9 text-center text-lg-end">
            <ul class="list-tags list-unstyled list-inline">
              <li class="list-inline-item">
                <router-link :to="tag1url" v-html="tag1"></router-link>
              </li>
              <li class="list-inline-item">
                <router-link :to="tag2url" v-html="tag2"> </router-link>
              </li>
              <li class="list-inline-item">
                <router-link :to="tag3url" v-html="tag3"></router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <h3 class="card-title" v-html="h3"></h3>
        <p class="lead card-text" v-html="lead"></p>
      </div>
    </div>
  </div>

  <div v-if="size == 3" class="col-12 col-lg-4">
    <div class="card">
      <div class="card-img">
        <img
          class="card-img-top"
          :src="require('@/assets/img/blog/' + img)"
          :alt="imgalt"
        />
        <img
          v-if="badge == 'none'"
          class="card-badge"
          src="@/assets/img/all/badge-none.svg"
          alt=""
        />
        <img
          v-if="badge == 'new'"
          class="card-badge"
          src="@/assets/img/all/badge-new.svg"
          alt="Újdonság"
        />
        <img
          v-if="badge == 'fresh'"
          class="card-badge"
          src="@/assets/img/all/badge-fresh.svg"
          alt="Friss"
        />
        <img
          v-if="badge == 'premium'"
          class="card-badge"
          src="@/assets/img/all/badge-premium.svg"
          alt="Prémium"
        />
      </div>
      <div class="card-header">
        <div class="row">
          <div class="col-12">
            <span class="list-date" v-html="createdate"></span>
          </div>
          <div class="col-12">
            <ul class="list-tags list-unstyled list-inline">
              <li class="list-inline-item">
                <router-link to="/blog/tanfolyam" v-html="tag1"></router-link>
              </li>
              <li class="list-inline-item">
                <router-link
                  to="/szolgaltatasaink/frontend-development"
                  v-html="tag2"
                >
                </router-link>
              </li>
              <li class="list-inline-item">
                <router-link
                  to="/szolgaltatasaink/frontend-development/vuejs"
                  v-html="tag3"
                ></router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <h3 class="card-title" v-html="h3"></h3>
        <p class="lead card-text" v-html="lead"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: Number,
    badge: String,
    url: String,
    img: String,
    imgalt: String,
    h3: String,
    lead: String,
    createdate: String,
    tag1: String,
    tag1url: String,
    tag2: String,
    tag2url: String,
    tag3: String,
    tag3url: String,
  },
};
</script>
